import React from "react";
import video1 from "../assets/video1.mp4";

export default function HeroSection() {
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.querySelector(targetId);
    const yOffset = -130; // Adjust this value to control the offset
    const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col items-center mt-6 lg:mt-1" id="hero-section">
      <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        הנדסת חשמל ותאורה
        <br />
        <span className="bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text">
          תכנון ייעוץ ופיקוח
        </span>
      </h1>
      <p className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
        מהנדס חשמל ותאורה מקצועי עם ניסיון עשיר בפרויקטי בנייה מגוונים, כולל
        תשתיות, מבני מגורים, ומבנים ציבוריים ותעשייתיים. אנו מציעים שירותי תכנון
        ופיתוח של מערכות חשמליות, קווי חשמל ותקשורת, מערכות גיבוי ועוד, תוך דגש
        על איכות, מקצועיות ועמידה בלוחות זמנים ותקציבים. הצטרפו ללקוחותינו
        המרוצים, כמו משרד הביטחון ודרך חדשה בתנועה בע"מ, והבטיחו לעצמכם ליווי
        מקצועי לאורך כל שלבי הפרויקט – מתכנון ועד מסירה. צרו קשר היום כדי לקבל
        ייעוץ ראשוני ולהתחיל את הפרויקט שלכם בידיים בטוחות.
      </p>
      <div className="flex justify-center my-10">
        <a
          href="#contact"
          onClick={(e) => handleScroll(e, "#contact")}
          className="bg-gradient-to-r from-orange-500 to-orange-800 py-3 px-4 mx-3 rounded-md"
        >
          לשיחת ייעוץ
        </a>
        <a href="#" className="py-3 px-4 mx-3 rounded-md border">
          עבודות לדוגמה
        </a>
      </div>
      <div className="flex mt-10 justify-center">
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-3/4 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}
