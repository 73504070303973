import React from "react";
import { Phone, Mail } from "lucide-react";

export default function Contact() {
  return (
    <div
      className="relative mt-20 border-b border-neutral-800 min-h-[800px]"
      id="contact"
    >
      <hr />
      <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center my-10 lg:my-20">
        צור איתנו קשר
      </h2>
      <div className="flex flex-wrap justify-center">
        <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-2">
          <div className="bg-neutral-900 rounded-md p-6 text-md border border-neutral-800 font-thin ">
            <div className="flex justify-between mb-4">
              <div className="flex items-center">
                <Phone className="h-6 w-6 mr-2 text-orange-500" />
                {/* Link only visible on mobile devices */}
                <a
                  href="tel:054-6886880"
                  className="text-lg text-orange-500 mobile-link"
                >
                  054-6886880
                </a>
                {/* Text only visible on desktop devices */}
                <p className="text-lg text-orange-500 desktop-link">
                  054-6886880
                </p>
              </div>

              <p className="text-lg text-orange-500">:טלפון</p>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <Mail className="h-6 w-6 mr-2 text-orange-500" />
                <p className="text-lg text-orange-500">igor@lojkin.com</p>
              </div>
              <p className="text-lg text-orange-500">:אימייל</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
