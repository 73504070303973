import { NotebookPen } from "lucide-react"; 
import { Zap } from "lucide-react";
import { HeartHandshake } from "lucide-react";
import { Drill  } from "lucide-react";
import { ShieldAlert } from "lucide-react";
import { PhoneIncoming  } from "lucide-react";

import user1 from "../assets/user1.png";
import user2 from "../assets/user2.png";
import user3 from "../assets/user3.png";
import user4 from "../assets/user4.png";
import user5 from "../assets/user5.png";
import user6 from "../assets/user6.png";
import user7 from "../assets/user7.png";

export const navItems = [
  { label: "צור קשר", href: "#contact" },
  { label: "לקוחות", href: "#customers" },
  { label: "תכנון הפרוייקט", href: "#feature-section" },
  { label: "קצת עלינו", href: "#hero-section" },
];

export const testimonials = [
  {
    user: "מילניום שירותים ולוגיסטיקה בע\"מ",
    image: user1,
    text: "קיבלנו שירות מקצועי ואיכותי. הוא הבין במלוא העומק את צרכי הפרויקט וסיפק פתרונות מותאמים אישית שעמדו בציפיותינו.",
  },
  {
    user: "דרך חדשה בתנועה",
    image: user2,
    text: "השירות שקיבלתי מאיגור היה מצוין. הוא התמקד בפרטים הקטנים ובהבנה מעמיקה של דרישות הפרויקט שלנו. תוצאה סופית מרשימה ומקצועית מאוד!",
  },
  {
    user: "משרד הביטחון",
    image: user3,
    text: "מהנדס החשמל הציע פתרונות מתקדמים ומותאמים אישית לצרכי הפרויקט שלנו. שירות מקצועי ואדיב ותוצאה שעמדה בכל הציפיות שלנו. תודה רבה!",
  },
  {
    user: "סינוס החשמל",
    image: user4,
    text: "עבודה מקצועית ומהירה עם תשומת לב לפרטים הקטנים. התקשורת הייתה מעולה לאורך כל התהליך והתוצאות היו מעל ומעבר לציפיות שלנו.",
  },
  {
    user: "קדימה מדע",
    image: user5,
    text: "שירות יוצא דופן ומקצועיות ברמה הגבוהה ביותר. הפרויקט נוהל בצורה חלקה ועמד בכל היעדים והלוחות זמנים שנקבעו.",
  },
  {
    user: "שבט בנימין בע\"מ",
    image: user6, 
    text: "הצוות סיפק פתרונות יצירתיים ומתקדמים שתרמו להצלחת הפרויקט. אנחנו מאוד מרוצים מהעבודה המשותפת ומהתוצאות המרשימות.",
  },
  {
    user: "מעיינות בניין פיתוח ותשתיות",
    image: user7, 
    text: "שירות מקצועי ואמין עם התמחות יוצאת דופן בתחום החשמל והתאורה. העבודה בוצעה ביעילות ובאיכות גבוהה, ואנו ממליצים בחום!",
  },
];


export const features = [
  {
    icon: <NotebookPen />,
    text: "ייעוץ טכני מתקדם והנדסתי בתחום המערכות החשמליות",
    description:
      "כל פרויקט בינוי, בין אם מסוג תשתיות, מבני מגורים או מבנים ציבוריים ותעשייתיים, כולל בתוכו מערכות חשמליות ותשתיות חשמל ותקשורת. למען כך, על הנדסת חשמל עומדת המשימה לשלב איש מקצוע מהתחום כיועץ פרויקט ולהבטיח את שלבי התכנון, הביצוע והפיקוח.",
  },
  {
    icon: <Zap />,
    text: "פיתוח ויישום פתרונות חדשניים ומתקדמים לבטיחות ויעילות אנרגטית",
    description:
      "מתכנני חשמל מפתחים ומיישמים מערכות טכנולוגיות חשמליות, מעגלים חשמליים, קווי חשמל ותקשורת, תוך התמקדות בביצועים אנרגטיים מיטביים ובשימוש יעיל במשאבים.",
  },
  {
    icon: <HeartHandshake />,
    text: "תמיכה טכנית מקצועית וייעוץ בפרויקטים מורכבים",
    description:
      "לאורך תהליך הפרויקט, מספקים ייעוץ ותמיכה טכנית, משתלבים בביקורות ומבצעים ביקורות חוזרות, ומספקים שירותי הנדסה לביצוע פרויקט באיכות גבוהה ובזמן המוגדר.",
  },
  {
    icon: <Drill />,
    text: "שירותים נוספים וייעוץ מקצועי במהלך הביצוע",
    description:
      "מספקים גם ליווי בשטח, טיפול באירועי תקלות, ומענה מקצועי לדרישות הפרויקט, על מנת להבטיח את הביצוע המוצלח של הפרויקט.",
  },
  {
    icon: <ShieldAlert />,
    text: "פיתוח מערכות חשמל מותאמות אישית ובטיחותיות",
    description:
      "מתכננים ומפתחים מערכות חשמל מותאמות אישית לצורכי הלקוח, בהתאם לדרישותיו הייחודיות ולסביבתו המיוחדת, ובעקבות הדרישות המחמירות הקיימות בתחום.",
  },
  {
    icon: <PhoneIncoming />,
    text: "ייעוץ וביצוע פתרונות חדשניים בתחום הטכנולוגיות החשמליות",
    description:
      "מספקים שירותי ייעוץ וייצור פתרונות טכנולוגיים מתקדמים בתחום החשמל, תוך התמקדות בפיתוח והנדסת מערכות מתקדמות ויעילות בטיחותית.",
  },
  
];
/*
export const checklistItems = [
  {
    title: "Code merge made easy",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Review code without worry",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "AI Assistance to reduce time",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
  {
    title: "Share work in minutes",
    description:
      "Track the performance of your VR apps and gain insights into user behavior.",
  },
];

export const pricingOptions = [
  {
    title: "Free",
    price: "$0",
    features: [
      "Private board sharing",
      "5 Gb Storage",
      "Web Analytics",
      "Private Mode",
    ],
  },
  {
    title: "Pro",
    price: "$10",
    features: [
      "Private board sharing",
      "10 Gb Storage",
      "Web Analytics (Advance)",
      "Private Mode",
    ],
  },
  {
    title: "Enterprise",
    price: "$200",
    features: [
      "Private board sharing",
      "Unlimited Storage",
      "High Performance Network",
      "Private Mode",
    ],
  },
];

export const resourcesLinks = [
  { href: "#", text: "Getting Started" },
  { href: "#", text: "Documentation" },
  { href: "#", text: "Tutorials" },
  { href: "#", text: "API Reference" },
  { href: "#", text: "Community Forums" },
];

export const platformLinks = [
  { href: "#", text: "Features" },
  { href: "#", text: "Supported Devices" },
  { href: "#", text: "System Requirements" },
  { href: "#", text: "Downloads" },
  { href: "#", text: "Release Notes" },
];

export const communityLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];
*/