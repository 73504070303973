import React from "react";
import { testimonials } from "../constants";

export default function Customers() {
  return (
    <div className="tracking-wide overflow-hidden" id="customers">
      <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center my-10 lg:my-20">
        הלקוחות שלנו
      </h2>
      <div className="flex justify-center overflow-hidden relative">
        <div className="flex animate-marquee">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 lg:w-1/3 px-4 py-2 flex-shrink-0"
            >
              <div className="bg-neutral-900 rounded-md p-6 text-md border border-neutral-800 font-thin">
                <div className="flex items-start mb-4">
                  <img
                    className="w-12 h-12 mr-4 rounded-full border border-neutral-300"
                    src={testimonial.image}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <h6 className="mt-2 text-right">{testimonial.user}</h6>
                    <p className="mt-5 text-center">{testimonial.text}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
